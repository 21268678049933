.entries {
  .action-icons {
    display: flex;
    justify-content: space-between;
  }

  .admin-icons {
    display: flex;
    justify-content: center;
  }

  .content {
    white-space: pre-line;
  }

  .title {
    text-align: center;
    color: azure;
    margin-top: 1rem;
  }

  .subtitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .timeline-image {
    margin-top: 0.5rem;
    border-radius: 0.25em;
  }
}
