.buttons-flex {
  display: flex;
  flex-direction: column;
}
.date-picker {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  box-sizing: border-box;
  width: inherit;
}
.spinner {
  position: absolute;
  top: 50%;
  left: 40%;
}
.add-user {
  display: flex;
}
.handleUserModal-modal-body {
  min-height: 25rem;
}
.td-flex {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.error {
  color: red;
  font-size: small;
}
.new-password-subtitle {
  font-size: smaller;
  margin-bottom: 0;
}
